
export default Text = (RTL) => {
  if(RTL) return {
    logo: 'اسطول',
    home: 'الرئيسية',
    language:"English",
    about:'من نحن',
    pricing: 'التسعير',
    contact:'الاتصال بنا',
    logIn:'تسجيل دخول',
    signUp: 'فتح حساب',
    aboutAs:`اسطول عبارة عن منصة تساعد في إدارة وتنظيم عملية التوصيل المحلية لديك ابتداءً من تتبع السائقين ومعرفة مواقعهم بالتحديد إلى التأكد من وصول الطلبات على وقتها. كما يمكن ربطها مع POS أو CRM أو أي نظام الكتروني خاص بشركتك.
        إضافة إلى ذلك، يعتبر نظام الدعم الأول لتوظيف السائقين؛ بحيث يمكنهم التسجيل من غير أي شروط باستثناء رخصة القيادة.
        `,
    start:'ابدأ الان',
    why:'لماذا يجب ان تختارنا',
    serviceOne:'خدمة الإرسال الآلي',
    automated:`تسهّل هذه الخاصية العملية الطويلة الخاصة بتوزيع الطلبات على السائقين بحيث أنها تمكنك من إدخال معلومات السائقين لمرة واحدة وتقسيم الطلبات وفقاً لحالتهم الراهنة حيث يتم تحديث حالتهم أوتوماتيكياً بشكل دائم!`,
    serviceTow:' خدمة التتبع الآلي',
    tracking:`معرفة موقع السائق بالتحديد وتتبع حالته توفّر وقتك وجهدك للتأكد من وصول الطلب على وقته.
`,
    serviceThree:' تحليل معلومات الطلبات',
    analysis:`يتم تسجيل كافة معلومات الطلبات، ابتداءً من اسم السائق المسؤول، وقت البدء، وقت الانتهاء، مدة التوصيل بالإضافة إلى أي ملاحظات بشأن كل طلب بحيث يمكن مراجعة هذه المعلومات، تحليلها وتطويرها لتحقيق الأهداف المهنية الخاصة بعملك.`,
    features:'المميزات',
    oneServe:'خدمة الإرسال الآلي',
    trackingServe:'خدمة التتبع الآلي',
    analysisServe:'تسجيل معلومات الطلبات وتحليلها',
    integration:`ربط النظام الإلكتروني مع أنظمة نقاط البيع`,
    feedback:'تقييم خدمة العملاء',
    alert:'استلام إشعارات فورية',
    performance:'تقرير تحليلات ونتائج الطلبات',
    chatting:'المحادثة مع السائقين ',
    barcode:'مسح الباركود',
    verification:'التأكد من العمر المسموح به لاستلام الطلب',
    accountSupport:'دعم خاصية تعدد الأعمال',
    prioritySupport:'دعم الأولوية',
    exportHistory:'استخراج الملف الخاص بتتبع الطلب ونتائجه',
    api:'خاصية ربط واجهة برمجة التطبيقات بأي نظام إلكتروني',
    freeOrders:'لا محدودية لعدد الطلبات ',
    product:'المنتج',
    company:'الشركة',
    support:'الدعم',
    contactUs:'الاتصال بنا',
    optimization:'الاقوى',
    visibility:'الرؤية',
    organization:'المنظمة',
    UserMonth:`المستخدم / الشهر`,
    Users:` ١٠٠+ المستخدمون`,
    enterprise:'المؤسسة',
    freeTrial:'بدء نسخة تجريبية مجانية',
    sales:'تواصل معنا لمعرفة الأسعار',
    delivery:'تتبع التسليم',
    management:'إدارة الأسطول',
    solutions:'حلول البطاقات البيضاء',
    careers:'الوظائف',
    blog:'مقالات',
    name:'تنظيم عملية التوصيل المحلية لديك أصبحت سهلة الآن',
    onlyPay:'ادفع فقط لما تحتاجه.يمكنك تغيير الحزم أو المستخدمين في أي وقت',
    callus:'اتصل بنا',
    year: 'بالشهر',
    month: 'بالسنة',
  }

  if(!RTL) return {
    logo: 'Esstoul',
    home: 'Home',
    language:"عربي",
    about:'About Us',
    pricing:'Pricing',
    contact:'Contact Us',
    logIn:'Log In',
    signUp:'Sign Up',
    aboutAs:`Estoul is a delivery operations platform for automated dispatching,
      real-time tracking and order analysis which make it the easiest way for
      members to manage their local delivery operations. It also can be integrated with POS,
      CRM or any online system seamlessly.
     Furthermore, it’s the number one support system for empowering drivers.
     They can register with no conditions except for having a driving licence.`,
    start:'GET STARTED NOW',
    why:'Why You Should Choose Us ',
    serviceOne:'Automated dispatching',
    automated: `With our powerful auto-dispatch feature, assigning drivers and
                  dispatching operations couldn’t be any easier.`,
    serviceTow:'Real-time tracking',
    tracking:`Tracking and receiving accurate live driver locations on the map provides a delightful accurate experience for a proof-of-delivery.`,
    serviceThree:'Orders Analysis',
    analysis:`Advanced analytics and detailed history for all operations from A-Z provide more productivity for any upcoming plan and helps optimizing delivery performance.`,
    features:'Features',
    oneServe:'Automated dispatching',
    trackingServe:'Real-time tracking',
    analysisServe:'Orders Analysis',
    integration:`POS/CRM Integration`,
    feedback:'Feedback',
    alert:'Receive automatic alerts',
    performance:'Performance and analysis reports',
    chatting:'Chatting feature with drivers',
    barcode:'Barcode scanning',
    verification:'Age verification',
    accountSupport:'Multi-brand account support',
    prioritySupport:'Priority support',
    exportHistory:'Analytics & export history',
    api:'API Integration',
    freeOrders:'Free unlimited orders',
    product:'PRODUCT',
    company:'COMPANY',
    support:'SUPPORT',
    contactUs:'CONTACT US',
    optimization:'Optimization',
    visibility:'Visibility',
    organization:'Organization',
    UserMonth:`User / Month`,
    Users:`100+ Users`,
    enterprise:'Enterprise',
    freeTrial:'Start free trial',
    sales:'Contact sales',
    delivery:'Delivery Tracking',
    management:'Fleet Management',
    solutions:'White Lable Solutions',
    careers:'Careers',
    blog:'Blog',
    name:'The ultimate way to manage your delivery operations!',
    onlyPay:'Only pay for what you need. Change packages or users at any time',
    callus:'Call Us',
    year: 'Year',
    month: 'Month',


  }
}
