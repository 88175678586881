import React from 'react';
import PropTypes from 'prop-types';
//
// const Modal = ({ toggleContact }) => (
//   <div className="modelContainer">
//
//     <div className="left-side">
//       <h2 className="bold">Contact us</h2>
//
//       <div className="info">
//         <span>Call</span>
//         <p>800-800-888</p>
//       </div>
//
//       <div className="info">
//         <span>Text</span>
//         <p>800-800-888</p>
//       </div>
//
//       <div className="info">
//         <span>Email</span>
//         <p>deliver@astool.com</p>
//       </div>
//
//       <div className="info">
//         <span>Chat</span>
//         <p>chat with us</p>
//       </div>
//
//       <div className="info">
//         <span>We&apos;re available</span>
//         <br/>
//         <span>24/7</span>
//       </div>
//
//     </div>
//     <div className="right-side">
//       <div className="lol">
//         <p>Or, fill out this form and we&apos;ll get back to you shortly</p>
//
//         <div className="row">
//           <div className="name">
//             <p>Name</p>
//             <input type="text" placeholder="Sam"/>
//           </div>
//           <div className="email">
//             <p>Email</p>
//             <input type="text" placeholder="sam@gmail.com"/>
//           </div>
//         </div>
//         <div className="message">
//           <p>Message</p>
//           <textarea placeholder="Add Message"> </textarea>
//         </div>
//         <button>Send Message</button>
//       </div>
//     </div>
//     <div onClick={toggleContact} className="modalshade" />
//   </div>
// );

const Modal = ({ toggleContact }) => (
  <div className="modelContainer">

    <div className="left-side">
      <h2 className="bold">Contact us</h2>

      <div className="info">
        <span>Call</span>
        <p>800-800-888</p>
      </div>

      <div className="info">
        <span>Text</span>
        <p>800-800-888</p>
      </div>

      <div className="info">
        <span>Email</span>
        <p>deliver@astool.com</p>
      </div>

      <div className="info">
        <span>Chat</span>
        <p>chat with us</p>
      </div>

      <div className="info">
        <span>We&apos;re available</span>
        <br/>
        <span>24/7</span>
      </div>

    </div>
    <div className="right-side">
      <div className="lol">
        <p>Or, fill out this form and we&apos;ll get back to you shortly</p>

        <div className="row">
          <div className="name">
            <p>Name</p>
            <input type="text" placeholder="Sam"/>
          </div>
          <div className="email">
            <p>Email</p>
            <input type="text" placeholder="sam@gmail.com"/>
          </div>
        </div>
        <div className="message">
          <p>Message</p>
          <textarea placeholder="Add Message"> </textarea>
        </div>
        <button onClick={toggleContact}>Send Message</button>
        <button style={{ background: 'grey' }} onClick={toggleContact}>Cancel</button>
      </div>
    </div>
    <div onClick={toggleContact} className="modalshade" />
  </div>
);

Modal.propTypes = {
  toggleContact: PropTypes.func,
};

export default Modal;
