import React from 'react';
import Switch from 'react-switch';
import Tilt from 'react-parallax-tilt';
import ScrollAnimation from 'react-animate-on-scroll';

import Modal from './components/modal';

import Phone from './assets/Phone.svg';
import Logo from './assets/Logo.png';
import './App.scss';
import 'animate.css/animate.min.css';
import Text from './data';

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      arabic: false,
      checked: false,
      modal: false,
    };

    this.toggleContact = this.toggleContact.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(checked) {
    this.setState({ checked });
  }

  toggleContact() {
    if (this.state.arabic) {
      return;
    }

    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }


  render() {
    const { arabic, checked, modal } = this.state;
    return (
      <div className={`mainContainer ${arabic && 'arabic'}`}>
        <div className="header ">
          <div className="logo">
            <img alt="logo" width={180} src={Logo} />
          </div>
          {modal && <Modal toggleContact={this.toggleContact} />}
          <div className="nav">
            <div className="ul nav-item ">
              <a className="li" href="#">{Text(arabic).home}</a>
            </div>
            <div className="ul nav-item ">
              <a className="li" onClick={() => window.scrollTo(0, this.about.offsetTop - 70)}>{Text(arabic).about}</a>
            </div>
            <div className="ul nav-item ">
              <a className="li" onClick={() => window.scrollTo(0, this.pricing.offsetTop - 70)}>{Text(arabic).pricing}</a>
            </div>
            <div className="ul nav-item">
              <a onClick={this.toggleContact} className="li contact">
                {Text(arabic).contact}
              </a>
            </div>
            <div className="ul nav-item">
              <a className={`li ${!arabic ? 'arabic-font' : 'english-font'}`} onClick={() => this.setState({ arabic: !arabic })}>{Text(arabic).language}</a>
            </div>
          </div>

          <div className="sign">
            <div className="nav-item ul">
              <a href="https://user.estoul.com" className="li">{Text(arabic).logIn}</a>
            </div>
            <button onClick={() => window.location = 'https://user.estoul.com'} className={`${arabic ? 'arabic-font' : 'english-font'}`} >{Text(arabic).signUp}</button>
          </div>
        </div>

        <div className="bodySection">
          <h1>{Text(arabic).name}</h1>
          <button onClick={() => window.location = 'https://user.estoul.com'} className={`${arabic ? 'arabic-font' : 'english-font'}`} >{Text(arabic).start}</button>
          <div className="imgbg" />
        </div>

        <div className="recommended">
          <ScrollAnimation
            animateIn="bounce"
            initiallyVisible
            animateOnce
          >
            <div className="advantages">
              <Tilt gyroscope>
                <div className="card">
                  <i className="fa fa-clock-o" aria-hidden="true" />
                  <div className=" inner-element text">
                    <p className="bold">{Text(arabic).serviceOne}</p>
                    <p>{Text(arabic).automated}</p>
                  </div>
                </div>
              </Tilt>
              <Tilt gyroscope>
                <div className="card">
                  <i className="fa fa-bolt" aria-hidden="true" />
                  <div className=" inner-element text">
                    <p className="bold">{Text(arabic).serviceTow}</p>
                    <p>
                      {Text(arabic).tracking}
                    </p>
                  </div>
                </div>
              </Tilt>
              <Tilt gyroscope>
                <div className="card">
                  <i className="fa fa-globe" />
                  <div className=" inner-element text">
                    <p className="bold">{Text(arabic).serviceThree}</p>
                    <p>
                      {Text(arabic).analysis}
                    </p>
                  </div>
                </div>
              </Tilt>
            </div>
          </ScrollAnimation>
        </div>

        <div ref={(ref) => this.about = ref} className="midNew">
          <div className="image">
            <img alt="phone" src={Phone} />
          </div>
          <div className="text">
            <h3>{Text(arabic).aboutAs}</h3>

          </div>

        </div>
        <div ref={(ref) => this.pricing = ref} className="tableSection">
          <div className="heading">
            <h1>{Text(arabic).pricing}</h1>
            <h3>{Text(arabic).onlyPay}</h3>
            <div className="toggleSwitch">
              <span>{Text(arabic).year}</span>
              <Switch
                checked={checked}
                onChange={this.handleChange}
                handleDiameter={30}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                height={20}
                width={48}
                offColor="#FFB904"
                onColor="#FFB904"
                className="react-switch"
                id="material-switch"
              />
              <span>{Text(arabic).month}</span>
            </div>
          </div>
          <div className="table">
            <div className="tableRow">
              <div className="col col1" />
              <div className="col bold col2"><p>{Text(arabic).organization}</p></div>
              <div className="col bold col3"><p>{Text(arabic).visibility}</p></div>
              <div className="col bold col4"><p>{Text(arabic).optimization}</p></div>
              <div className="col bold col5"><p>{Text(arabic).enterprise}</p></div>
            </div>
            <div className="tableRow">
              <div className="col col1" />
              <div className="col col2">
                <p className="coins">$</p>
                <p className="inlarge">{!checked ? 30 : 40}</p>
              </div>
              <div className="col col3">
                <p className="coins">$</p>
                <p className="inlarge">{!checked ? 50 : 60}</p>
              </div>
              <div className="col col4">
                <p className="coins">$</p>
                <p className="inlarge">{!checked ? 70 : 80}</p>
              </div>
              <div className="col col5"><button className="call">{Text(arabic).callus}</button></div>
            </div>
            <div className="tableRow">
              <div className="col col1"><p>{Text(arabic).features}</p></div>
              <div className="col col2"><p>{Text(arabic).UserMonth}</p></div>
              <div className="col col3"><p>{Text(arabic).UserMonth}</p></div>
              <div className="col col4"><p>{Text(arabic).UserMonth}</p></div>
              <div className="col col5"><p>{Text(arabic).Users}</p></div>
            </div>
            <div className="tableRow">
              <div className="col"><p>{Text(arabic).oneServe}</p></div>
              <div className="col col2"><i className="fa fa-check" aria-hidden="true" /></div>
              <div className="col col3"><i className="fa fa-check" aria-hidden="true" /></div>
              <div className="col col4"><i className="fa fa-check" aria-hidden="true" /></div>
              <div className="col col5"><i className="fa fa-check" aria-hidden="true" /></div>
            </div>
            <div className="tableRow">
              <div className="col"><p>{Text(arabic).trackingServe}</p></div>
              <div className="col col2"><i className="fa fa-check" aria-hidden="true" /></div>
              <div className="col col3"><i className="fa fa-check" aria-hidden="true" /></div>
              <div className="col col4"><i className="fa fa-check" aria-hidden="true" /></div>
              <div className="col col5"><i className="fa fa-check" aria-hidden="true" /></div>
            </div>
            <div className="tableRow">
              <div className="col"><p>{Text(arabic).analysisServe}</p></div>
              <div className="col col2"><i className="fa fa-check" aria-hidden="true" /></div>
              <div className="col col3"><i className="fa fa-check" aria-hidden="true" /></div>
              <div className="col col4"><i className="fa fa-check" aria-hidden="true" /></div>
              <div className="col col5"><i className="fa fa-check" aria-hidden="true" /></div>
            </div>
            <div className="tableRow">
              <div className="col"><p>{Text(arabic).integration}</p></div>
              <div className="col col2"><i className="fa fa-check" aria-hidden="true" /></div>
              <div className="col col3"><i className="fa fa-check" aria-hidden="true" /></div>
              <div className="col col4"><i className="fa fa-check" aria-hidden="true" /></div>
              <div className="col col5"><i className="fa fa-check" aria-hidden="true" /></div>
            </div>
            <div className="tableRow">
              <div className="col"><p>{Text(arabic).feedback}</p></div>
              <div className="col col2"><i className="fa fa-check" aria-hidden="true" /></div>
              <div className="col col3"><i className="fa fa-check" aria-hidden="true" /></div>
              <div className="col col4"><i className="fa fa-check" aria-hidden="true" /></div>
              <div className="col col5"><i className="fa fa-check" aria-hidden="true" /></div>
            </div>
            <div className="tableRow">
              <div className="col"><p>{Text(arabic).alert}</p></div>
              <div className="col col2"><i className="fa fa-check" aria-hidden="true" /></div>
              <div className="col col3" />
              <div className="col col4"><i className="fa fa-check" aria-hidden="true" /></div>
              <div className="col col5"><i className="fa fa-check" aria-hidden="true" /></div>
            </div>
            <div className="tableRow">
              <div className="col"><p>{Text(arabic).performance}</p></div>
              <div className="col col2"><i className="fa fa-check" aria-hidden="true" /></div>
              <div className="col col3" />
              <div className="col col4"><i className="fa fa-check" aria-hidden="true" /></div>
              <div className="col col5"><i className="fa fa-check" aria-hidden="true" /></div>
            </div>
            <div className="tableRow">
              <div className="col"><p>{Text(arabic).chatting}</p></div>
              <div className="col col2" />
              <div className="col col3" />
              <div className="col col4"><i className="fa fa-check" aria-hidden="true" /></div>
              <div className="col col5"><i className="fa fa-check" aria-hidden="true" /></div>
            </div>
            <div className="tableRow">
              <div className="col"><p>{Text(arabic).barcode}</p></div>
              <div className="col col2" />
              <div className="col col3" />
              <div className="col col4"><i className="fa fa-check" aria-hidden="true" /></div>
              <div className="col col5"><i className="fa fa-check" aria-hidden="true" /></div>
            </div>
            <div className="tableRow">
              <div className="col"><p>{Text(arabic).verification}</p></div>
              <div className="col col2" />
              <div className="col col3" />
              <div className="col col4"><i className="fa fa-check" aria-hidden="true" /></div>
              <div className="col col5"><i className="fa fa-check" aria-hidden="true" /></div>
            </div>
            <div className="tableRow">
              <div className="col"><p>{Text(arabic).accountSupport}</p></div>
              <div className="col col2" />
              <div className="col col3" />
              <div className="col col4"><i className="fa fa-check" aria-hidden="true" /></div>
              <div className="col col5"><i className="fa fa-check" aria-hidden="true" /></div>
            </div>
            <div className="tableRow">
              <div className="col"><p>{Text(arabic).prioritySupport}</p></div>
              <div className="col col2" />
              <div className="col col3" />
              <div className="col col4"><i className="fa fa-check" aria-hidden="true" /></div>
              <div className="col col5"><i className="fa fa-check" aria-hidden="true" /></div>
            </div>
            <div className="tableRow">
              <div className="col"><p>{Text(arabic).exportHistory}</p></div>
              <div className="col col2" />
              <div className="col col3" />
              <div className="col col4"><i className="fa fa-check" aria-hidden="true" /></div>
              <div className="col col5"><i className="fa fa-check" aria-hidden="true" /></div>
            </div>
            <div className="tableRow">
              <div className="col"><p>{Text(arabic).api}</p></div>
              <div className="col col2" />
              <div className="col col3" />
              <div className="col col4"><i className="fa fa-check" aria-hidden="true" /></div>
              <div className="col col5"><i className="fa fa-check" aria-hidden="true" /></div>
            </div>
            <div className="tableRow">
              <div className="col"><p>{Text(arabic).freeOrders}</p></div>
              <div className="col col2" />
              <div className="col col3" />
              <div className="col col4"><i className="fa fa-check" aria-hidden="true" /></div>
              <div className="col col5"><i className="fa fa-check" aria-hidden="true" /></div>
            </div>
            <div className="tableRow">
              <div className="col" />
              <div className="col col2 " ><button>{Text(arabic).freeTrial}</button></div>
              <div className="col col3"><button>{Text(arabic).freeTrial}</button></div>
              <div className="col col4"><button>{Text(arabic).sales}</button></div>
              <div className="col col5"><button>{Text(arabic).sales}</button></div>
            </div>
          </div>
        </div>


        <div className="footer">
          <div className="footerHeading">
            <span className="bold">{Text(arabic).product}</span>
            <p>{Text(arabic).delivery}</p>
            <p>{Text(arabic).management}</p>
            <p>{Text(arabic).solutions}</p>
          </div>

          <div className="footerHeading">
            <span className="bold">{Text(arabic).company}</span>
            <p>{Text(arabic).about}</p>
            <p>{Text(arabic).careers}</p>
            <p>{Text(arabic).blog}</p>
            <p>{Text(arabic).contact}</p>
          </div>

          <div className="footerHeading">
            <span className="bold">{Text(arabic).support}</span>
            <p>FAQ</p>
            <p className={`normal ${!arabic ? 'arabic-font' : 'english-font'}`} onClick={() => this.setState({ arabic: !arabic })}>{Text(arabic).language}</p>
          </div>

          <div className="footerHeading">
            <span className="bold">{Text(arabic).contactUs}</span>
            <p className={` ${arabic ? 'arabic-font' : 'english-font'}`} ><a className="normal" href="tel:+96265561975">+962 (06) 556 1975</a></p>
            <p className={` ${arabic ? 'arabic-font' : 'english-font'}`} ><a className="normal" href="mailto:hello@estoul.com">hello@estoul.com</a></p>
          </div>

          <div className="logo footer-logo">
            <img alt="logo" width={180} src={Logo} />
          </div>
        </div>

      </div>

    );
  }
}

export default App;
